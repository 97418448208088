html,
body,
#root,
.App {
  min-height: 100vh;
  height: auto;
}
.ant-layout{
  min-height: 100vh;
  height: auto;
}